import {
  AppointmentsFilters,
  BookingState,
  ConfirmedAppointment,
  ConfirmedReservation,
  Filters,
  SaveForLaterFavorite,
  UnconfirmedReservation
} from "core/domain/booking/BookingState";
import { BookingStep } from "core/domain/booking/Enums";
import { Practice } from "core/domain/booking/Practice";
import { BookingStorageService } from "core/services/booking/BookingStorageService";
import { guid } from "core/types";
import { DateTime } from "luxon";
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
  toJS
} from "mobx";
import { ProviderOnlineStatusCodes } from "modules/bhb/pages/select-appointment-slot/types";
import { RouteMap } from "modules/bhb/RouteMap";
import { NavigateOptions, To } from "react-router-dom";
import { ReCaptchaInstance } from "recaptcha-v3";

import { AppointmentTypeDto, ProviderDto } from "../services/booking";
import { StorageKey } from "./Storage";

export class BookingStore implements BookingState {
  private _suspendSaveToLocalStorage = false;

  private _practice?: Practice;
  private _isUserProfileComplete?: boolean;

  private _step: BookingStep = BookingStep.SelectPatientType;
  private _isNewPatient?: boolean;
  private _filters: Filters = {};
  private _appointmentFilters: AppointmentsFilters = {};
  private _reservation?: ConfirmedReservation;
  private _unconfirmedReservation?: UnconfirmedReservation;
  private _isAppointmentForUser?: boolean;
  private _dependantId?: string;
  private _appointment?: ConfirmedAppointment;
  private _rescheduleAppointmentId?: string;
  private _patientNotes?: string;

  private _availabilityFromDate: DateTime = DateTime.now().startOf("day");

  private _reCaptchaInstance?: ReCaptchaInstance;
  private _reCaptchaToken: string | null = null;

  private _providerDetailsId: string | undefined;

  private _appointmentTypes?: AppointmentTypeDto[];
  private _providers?: ProviderDto[];
  private _saveForLaterFavorite?: SaveForLaterFavorite;

  public constructor() {
    makeObservable<
      BookingStore,
      | "_practice"
      | "_isUserProfileComplete"
      | "_step"
      | "_isNewPatient"
      | "_filters"
      | "_reservation"
      | "_unconfirmedReservation"
      | "_isAppointmentForUser"
      | "_dependantId"
      | "_appointment"
      | "_rescheduleAppointmentId"
      | "_patientNotes"
      | "_availabilityFromDate"
      | "_reCaptchaInstance"
      | "_reCaptchaToken"
      | "nextStep"
      | "previousStep"
      | "_providerDetailsId"
      | "_appointmentTypes"
      | "_providers"
      | "_saveForLaterFavorite"
      | "postLogoutRedirectUri"
      | "_appointmentFilters"
    >(this, {
      _practice: observable,
      _isUserProfileComplete: observable,

      _step: observable,
      _isNewPatient: observable,
      _filters: observable,
      _appointmentFilters: observable,
      _reservation: observable,
      _unconfirmedReservation: observable,
      _isAppointmentForUser: observable,
      _dependantId: observable,
      _appointment: observable,
      _rescheduleAppointmentId: observable,
      _patientNotes: observable,

      _availabilityFromDate: observable,
      _reCaptchaInstance: observable,
      _reCaptchaToken: observable,

      // See: https://stackoverflow.com/a/68067250/127497
      availabilityFromDate: computed,
      reCaptchaInstance: computed,
      reCaptchaToken: computed,
      canSelectAppointmentType: computed,
      isUserProfileComplete: computed,

      practiceId: computed,
      practice: computed,
      previousStep: computed,
      nextStep: computed,

      postLogoutRedirectUri: computed,

      updateBookingState: action.bound,
      tryRestoreCurrentBooking: action.bound,
      resetWizardState: action.bound,

      _providerDetailsId: observable,
      _appointmentTypes: observable,
      _providers: observable,
      _saveForLaterFavorite: observable
    });

    reaction(
      () => ({
        step: this.step,
        practiceId: this.practiceId,
        isNewPatient: this.isNewPatient,
        filters: toJS(this.filters),
        appointmentFilters: toJS(this.appointmentFilters),
        reservation: this.reservation,
        patientNotes: this.patientNotes,
        isAppointmentForUser: this.isAppointmentForUser,
        dependantId: this.dependantId,
        appointment: this.appointment,
        unconfirmedReservation: this.unconfirmedReservation,
        rescheduleAppointmentId: this.rescheduleAppointmentId,
        saveForLaterFavorite: this.saveForLaterFavorite
      }),
      state => {
        if (this._suspendSaveToLocalStorage) {
          this._suspendSaveToLocalStorage = false;
        } else {
          BookingStorageService.storeState(state);
        }
      }
    );
  }

  public get practiceId(): guid | undefined {
    if (this._practice?.id) return this._practice?.id;

    const bookingStorage = localStorage.getItem(StorageKey.BHB_WIZARD_MODEL);
    if (bookingStorage) {
      return JSON.parse(bookingStorage).practiceId;
    }
    return undefined;
  }

  public get practice(): Practice {
    return this._practice!;
  }

  public get isUserProfileComplete(): boolean | undefined {
    return this._isUserProfileComplete;
  }

  public set isUserProfileComplete(next: boolean | undefined) {
    this._isUserProfileComplete = next;
  }

  public get step() {
    return this._step;
  }

  public get isNewPatient() {
    return this._isNewPatient;
  }

  public get filters() {
    return this._filters;
  }

  public get appointmentFilters() {
    return this._appointmentFilters;
  }

  public get unconfirmedReservation() {
    return this._unconfirmedReservation;
  }

  public get reservation() {
    return this._reservation;
  }

  public get isAppointmentForUser() {
    return this._isAppointmentForUser;
  }

  public get dependantId() {
    return this._dependantId;
  }

  public get patientNotes() {
    return this._patientNotes;
  }

  public get appointment() {
    return this._appointment;
  }

  public get rescheduleAppointmentId() {
    return this._rescheduleAppointmentId;
  }

  public get availabilityFromDate() {
    return this._availabilityFromDate;
  }

  public set availabilityFromDate(next: DateTime) {
    this._availabilityFromDate = next;
  }

  public get reCaptchaInstance() {
    return this._reCaptchaInstance;
  }

  public set reCaptchaInstance(next: ReCaptchaInstance | undefined) {
    this._reCaptchaInstance = next;
  }

  public get reCaptchaToken() {
    return this._reCaptchaToken;
  }

  public set reCaptchaToken(next: string | null) {
    this._reCaptchaToken = next;
  }

  public get providerDetailsId() {
    return this._providerDetailsId;
  }

  public get saveForLaterFavorite() {
    return this._saveForLaterFavorite;
  }

  public set saveForLaterFavorite(
    saveForLaterFavorite: SaveForLaterFavorite | undefined
  ) {
    runInAction(() => {
      this._saveForLaterFavorite = saveForLaterFavorite;
    });
  }

  public set providerDetailsId(id: string | undefined) {
    runInAction(() => {
      this._providerDetailsId = id;
    });
  }

  public get canSelectAppointmentType() {
    return (
      this.isNewPatient === false ||
      !this.practice?.bookingSettings?.allowNewPatients
    );
  }

  public get appointmentTypes() {
    return this._appointmentTypes ?? [];
  }

  public get providers() {
    return this._providers ?? [];
  }

  private get hasAppointmentTypeInformation() {
    const appointmentType = this.appointmentTypes.find(
      at => at.id === this.filters.appointmentTypeId
    );
    return appointmentType?.appointmentInformationEnabled ?? false;
  }

  public updateBookingState(delta: Partial<BookingState>) {
    if (!delta) return;

    Object.keys(delta).forEach(key => {
      if (this[key] !== delta[key]) {
        this[`_${key}`] = delta[key];
      }
    });
  }

  public updateFilterState = (delta: Partial<Filters>) => {
    runInAction(() => {
      if (!delta) return;

      Object.keys(delta).forEach(key => {
        if (this._filters[key] !== delta[key]) {
          this._filters[key] = delta[key];
        }
      });
    });
  };

  public updateAppointmentsFilterState = (
    delta: Partial<AppointmentsFilters>
  ) => {
    runInAction(() => {
      if (!delta) return;

      Object.keys(delta).forEach(key => {
        if (this._appointmentFilters[key] !== delta[key]) {
          this._appointmentFilters[key] = delta[key];
        }
      });
    });
  };

  public resetFilterState = () => {
    runInAction(() => {
      this._filters = { appointmentTypeId: this.filters.appointmentTypeId };
    });
  };

  public resetAppointmentsFilterState = () => {
    runInAction(() => {
      this._appointmentFilters = {};
    });
  };

  public tryRestoreCurrentBooking(
    practice: Practice,
    appointmentTypes?: AppointmentTypeDto[],
    providers?: ProviderDto[]
  ): boolean {
    this._practice = practice;
    this._appointmentTypes = appointmentTypes;
    this._providers = providers;

    const restored = BookingStorageService.restoreState(practice.id);
    if (!restored) return true;

    this._suspendSaveToLocalStorage = true;
    this.updateBookingState(restored);
    return true;
  }

  public resetWizardState() {
    this._isNewPatient = undefined;
    this._filters = {};
    this._reservation = undefined;
    this._unconfirmedReservation = undefined;
    this._isAppointmentForUser = undefined;
    this._dependantId = undefined;
    this._patientNotes = undefined;
  }

  public get postLogoutRedirectUri() {
    return this.practiceId
      ? RouteMap.getBookingStepPath(this.step, this.practiceId, true)
      : "/";
  }

  public isPatientNotesEnabled = (appointmentTypeId: string) => {
    return (
      this.appointmentTypes.find(x => x.id === appointmentTypeId)
        ?.allowPatientNotes === true
    );
  };

  /**
   * Get the logical "current" step based on what information has been filled out in the booking wizard.
   */
  public calculateLogicalStep(requestedStep: BookingStep): BookingStep {
    const {
      practice,
      isNewPatient,
      isAppointmentForUser,
      dependantId,
      reservation,
      appointment
    } = this;

    // Check for Practice Outage
    if (practice.bookingSettings.practiceOutage) {
      return BookingStep.PracticeOutage;
    }

    // immediately check if only booking are enabled and practice has at least one assign appointment type
    // Ensure not all providers are set to Call Clinic
    if (
      !practice?.bookingSettings.isEnabled ||
      !this.appointmentTypes?.length ||
      !this.providers?.length ||
      this.providers.every(
        x => x.providerOnlineStatus === ProviderOnlineStatusCodes.call
      )
    )
      return BookingStep.NotAcceptingBookings;

    if (
      requestedStep === BookingStep.SelectAppointmentSlot &&
      this.rescheduleAppointmentId
    ) {
      return BookingStep.SelectAppointmentSlot;
    }

    if (requestedStep === BookingStep.NotAcceptingNewPatients) {
      return BookingStep.NotAcceptingNewPatients;
    }

    if (
      requestedStep === BookingStep.NotAcceptingBookings &&
      practice.bookingSettings.isEnabled
    ) {
      return BookingStep.NotAcceptingBookings;
    }

    const hasPatient = !!(isAppointmentForUser || dependantId);

    if (
      requestedStep > BookingStep.SelectPatientType &&
      isNewPatient === false && // Using strict equality as this will be undefined on the BookingSuccess page after it calls resetWizardState
      this.appointmentTypes.filter(x => x.isAvailableExistingPatients === true)
        .length === 0
    ) {
      return BookingStep.NotAcceptingBookings;
    }

    if (
      requestedStep > BookingStep.SelectPatientType &&
      isNewPatient &&
      this.appointmentTypes.filter(x => x.isAvailableNewPatients === true)
        .length === 0
    ) {
      return BookingStep.NotAcceptingNewPatients;
    }

    if (this._isUserProfileComplete) {
      if (requestedStep === BookingStep.BookingSuccess && appointment)
        return BookingStep.BookingSuccess;
      if (requestedStep >= BookingStep.ConfirmBooking && hasPatient)
        return BookingStep.ConfirmBooking;
      if (requestedStep === BookingStep.AddDependant && !!reservation)
        return BookingStep.AddDependant;
      if (
        requestedStep === BookingStep.AddPatientNotes &&
        !!reservation &&
        this.isPatientNotesEnabled(reservation.appointmentTypeId)
      )
        return BookingStep.AddPatientNotes;
      if (requestedStep >= BookingStep.SelectPatient && !!reservation)
        return BookingStep.SelectPatient;
    }

    if (requestedStep >= BookingStep.CompleteProfile) {
      if (this._isUserProfileComplete) return BookingStep.SelectPatient;
      if (this._isUserProfileComplete === false)
        return BookingStep.CompleteProfile;

      // The user is not logged in, continue to select patient to prompt for login
      return BookingStep.SelectPatient;
    }

    if (requestedStep >= BookingStep.SelectPatient && !!reservation) {
      // User has logged in but not completed their profile
      return this._isUserProfileComplete !== false
        ? BookingStep.SelectPatient
        : BookingStep.CompleteProfile;
    }

    if (requestedStep === BookingStep.SelectAppointmentType) {
      return BookingStep.SelectAppointmentType;
    }

    if (requestedStep === BookingStep.AppointmentTypeInformation) {
      return BookingStep.AppointmentTypeInformation;
    }

    if (requestedStep === BookingStep.SelectAppointmentSlot) {
      return BookingStep.SelectAppointmentSlot;
    }

    return BookingStep.SelectPatientType;
  }

  private get previousStep(): BookingStep {
    // Don't let them go back after confirming. In theory this will never be called because we don't show a
    // "back" button on this page.
    if (this._step === BookingStep.ConfirmBooking)
      return this.isPatientNotesEnabled(this.reservation!.appointmentTypeId)
        ? BookingStep.AddPatientNotes
        : BookingStep.SelectPatient;
    if (this._step === BookingStep.AddPatientNotes)
      return BookingStep.SelectPatient;
    if (this._step === BookingStep.AddDependant)
      return BookingStep.SelectPatient;
    if (this._step === BookingStep.SelectPatient)
      return BookingStep.SelectAppointmentSlot;
    if (this._step === BookingStep.AppointmentTypeInformation)
      return BookingStep.SelectAppointmentType;
    if (this._step === BookingStep.SelectAppointmentSlot) {
      return this.hasAppointmentTypeInformation
        ? BookingStep.AppointmentTypeInformation
        : BookingStep.SelectAppointmentType;
    }

    return BookingStep.SelectPatientType;
  }

  private get nextStep(): BookingStep {
    if (this._isUserProfileComplete) {
      if (this._step === BookingStep.ConfirmBooking)
        return BookingStep.BookingSuccess;
      if (
        this._step === BookingStep.AddDependant &&
        this.reservation &&
        this.dependantId
      )
        return this.isPatientNotesEnabled(this.reservation.appointmentTypeId)
          ? BookingStep.AddPatientNotes
          : BookingStep.ConfirmBooking;

      if (this._step === BookingStep.AddPatientNotes && this.reservation) {
        return BookingStep.ConfirmBooking;
      }
      if (this._step === BookingStep.SelectPatient && this.reservation) {
        // Next screen could be confirm booking or add patient notes
        const nextScreen = this.isPatientNotesEnabled(
          this.reservation!.appointmentTypeId
        )
          ? BookingStep.AddPatientNotes
          : BookingStep.ConfirmBooking;

        return this.isAppointmentForUser || this.dependantId
          ? nextScreen
          : BookingStep.AddDependant;
      }

      if (
        this._step === BookingStep.SelectAppointmentSlot &&
        this.reservation?.id
      ) {
        return this._isUserProfileComplete
          ? BookingStep.SelectPatient
          : BookingStep.CompleteProfile;
      }
    }

    if (this._step === BookingStep.CompleteProfile) {
      return this.calculateLogicalStep(BookingStep.ConfirmBooking);
    }

    if (
      this._step >= BookingStep.SelectAppointmentSlot &&
      !this._isUserProfileComplete
    )
      return BookingStep.CompleteProfile;

    if (this._step === BookingStep.SelectAppointmentType) {
      return this.hasAppointmentTypeInformation
        ? BookingStep.AppointmentTypeInformation
        : BookingStep.SelectAppointmentSlot;
    }

    if (this._step === BookingStep.AppointmentTypeInformation) {
      return BookingStep.SelectAppointmentSlot;
    }

    if (this._step === BookingStep.SelectPatientType) {
      return BookingStep.SelectAppointmentType;
    }

    return BookingStep.SelectPatientType;
  }

  public getNavigationArgs = (
    step: BookingStep,
    forceReplace = false
  ): [To, NavigateOptions] => {
    if (!this.practiceId) throw Error("locationId is required.");

    const replace = forceReplace || BookingStore.shouldReplaceHistory(step);

    const args = [
      RouteMap.getBookingStepPath(step, this.practiceId, true),
      { replace }
    ];

    return args as [To, NavigateOptions];
  };

  public getPreviousStepNavigationArgs = () =>
    this.getNavigationArgs(this.previousStep);
  public getNextStepNavigationArgs = () =>
    this.getNavigationArgs(this.nextStep);

  private static shouldReplaceHistory(step: BookingStep) {
    // When the user goes submits the "Add someone new" form, we need to remove this page from the browser stack.
    // That way, if they click the browser back button, they'll be returned to the "Who is this appointment for" page
    // where their new dependant will now be on the list.
    return step === BookingStep.AddDependant;
  }
}
