import Hotjar from "@hotjar/browser";

import { Environment } from "../../domain";

// @TODO should be added in Terraform -> appConfig
const SITE_ID_PRODA = 5067324;
const SITE_ID_UATA = 5067165;
const HOTJAR_VERSION = 6;

export const initHotJar = () => {
  if (
    window.appConfig.environment !== Environment.Dev &&
    !window.appConfig.isLocal
  ) {
    const siteId =
      window.appConfig.environment === Environment.Prod
        ? SITE_ID_PRODA
        : SITE_ID_UATA;
    Hotjar.init(siteId, HOTJAR_VERSION);
  }
};
