import { Literal } from "components/Literal";
import { Notification } from "core/store/NotificationStore";
import { useNotificationStore } from "core/store/RootStore";
import { observer } from "mobx-react-lite";
import { SyntheticEvent, useCallback } from "react";

import { Alert, Slide, SlideProps, Snackbar } from "@mui/material";

interface NotificationCountProps {
  count: number;
  hide?: boolean;
}

const NotificationTally = ({ count, hide }: NotificationCountProps) => {
  if (hide || count <= 1) return null;
  return <span>({count})</span>;
};

const SlideRight = (props: SlideProps) => (
  <Slide {...props} direction="right" />
);

interface ToastProps {
  notification: Notification;
  onDismiss: (notification: Notification) => void;
}

const Toast = observer(({ notification, onDismiss }: ToastProps) => {
  const autoHideDuration = notification.expiresAtMillis
    ? notification.expiresAtMillis - new Date().valueOf()
    : null;

  const handleDismiss = () => onDismiss(notification);
  const handleClose = (event: SyntheticEvent, reason: string) =>
    reason !== "clickaway" && handleDismiss();

  const hasTitle = !!notification.title;
  const messageVariant = hasTitle ? "body1" : "title2";

  return (
    <Snackbar
      open={true}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      TransitionComponent={SlideRight}
    >
      <Alert onClose={handleDismiss} severity={notification.type}>
        <>
          {hasTitle && (
            <Literal variant="title2" sx={{ p: 0 }}>
              {notification.title}{" "}
              <NotificationTally count={notification.count} />
            </Literal>
          )}
          <Literal variant={messageVariant}>
            {notification.message}
            <NotificationTally count={notification.count} hide={hasTitle} />
          </Literal>
        </>
      </Alert>
    </Snackbar>
  );
});

const Notifications = observer(() => {
  const store = useNotificationStore();
  const handleDismiss = useCallback(
    (notification: Notification) => store.dismiss(notification.id),
    [store]
  );

  // Only show one notification at a time (in line with Material UI guidelines - https://material.io/components/snackbars#usage)
  const notification = store.current;
  if (!notification) return null;

  return (
    <>
      <Toast
        key={notification.id}
        notification={notification}
        onDismiss={handleDismiss}
      />
    </>
  );
});

// eslint-disable-next-line import/no-default-export
export default Notifications;
