import { useUserAgent } from "@oieduardorabelo/use-user-agent";

export const useIsMobileView = () => {
  const details = useUserAgent();

  // determines if this is a mobile embedded webview i.e. a webview from IOS or Android from BHA
  return (
    (details &&
      details.browser &&
      (details.browser.name === "WebKit" ||
        details.browser.name === "Chrome WebView")) ||
    (details && details.device && details.device.type === "mobile")
  );
};
