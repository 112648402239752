import { Settings } from "luxon";

const deepMerge = <T extends Object>(...objects: Partial<T>[]) => {
  return objects.reduce((src, dest) => {
    if (!dest) return src;

    Object.keys(src).forEach(key => {
      const destValue = dest[key];
      const srcValue = src[key];

      dest[key] =
        typeof srcValue === "object"
          ? deepMerge(srcValue, destValue)
          : destValue ?? srcValue;
    });

    return dest;
  });
};

/**
 * Default configuration values extracted from .env files. undefined values will be overwritten by whatever gets loaded from
 * the /public/config.json file. See ConfigLoader for details.
 */
const envConfig = {
  msal: {
    clientId: import.meta.env.VITE_MSAL_CLIENT_ID || undefined,
    authority: import.meta.env.VITE_MSAL_AUTHORITY || undefined
  },

  locale: "en-AU",
  dateFormatPattern: "dd/mm/yyyy",
  gaMeasurementId: import.meta.env.VITE_GA_MEASUREMENT_ID || undefined,
  appInsightsInstrumentationKey: import.meta.env.VITE_APP_INSIGHTS || undefined,

  bhacApiUrl: import.meta.env.VITE_BHAC_API_URL || undefined,
  bhbApiUrl: import.meta.env.VITE_BHB_API_URL || undefined,
  bhbUrl: import.meta.env.VITE_BHB_URL || undefined,
  bhwUrl: import.meta.env.VITE_BHW_URL || undefined,
  isLocal: import.meta.env.VITE_IS_LOCAL || undefined,

  query: {
    staleTimeMillis: import.meta.env.VITE_QUERY_STALE_TIME_MILLIS
      ? parseInt(import.meta.env.VITE_QUERY_STALE_TIME_MILLIS, 10)
      : undefined
  }
} as Partial<RuntimeConfig>;

export const config = deepMerge(
  {},
  window.appConfig,
  envConfig
) as RuntimeConfig;

// eslint-disable-next-line no-console
console.log(`Setting locale for date/times to: ${config.locale}`);
Settings.defaultLocale = config.locale;
