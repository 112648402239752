import { FunctionComponent } from "react";
import { FallbackProps } from "react-error-boundary";

import { ErrorBox } from "./ErrorBox";

export const ErrorBoundaryFallback: FunctionComponent<FallbackProps> = ({
  error,
  children
}) => {
  if (error) {
    return <ErrorBox error={error} />;
  }
  return <>{children}</>;
};
