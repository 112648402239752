import { AppRoutes } from "AppRoutes";

import { ErrorBoundaryFallback } from "components/ErrorBoundaryFallback";
import { theme } from "components/theme";
import { config } from "config";
import { Environment } from "core/domain";
import ReactQueryProvider from "core/services/ReactQueryProvider";
import { StoreContextProvider } from "core/store/RootStore";
import { ErrorBoundary } from "react-error-boundary";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";

import { MsalProvider } from "@azure/msal-react";
import { configure, getAppInstance } from "@bps/msal";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import AdapterLuxon from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeProvider from "@mui/material/styles/ThemeProvider";

import { useWindowHeightResize } from "./components/hooks/useWindowHeightResize";
import { initAppInsights } from "./core/analitics/app-insights/app-insights.utils";
import { initHotJar } from "./core/analitics/hotjar/hot-jar.utils";

configure(config.msal);
const appInsightPlugin = initAppInsights();
initHotJar();

function App() {
  useWindowHeightResize();
  return (
    <AppInsightsContext.Provider value={appInsightPlugin}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <ReactQueryProvider>
          <StoreContextProvider>
            <MsalProvider instance={getAppInstance()}>
              <LocalizationProvider
                locale={config.locale}
                dateAdapter={AdapterLuxon}
              >
                <Router>
                  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                    <AppRoutes />
                  </ErrorBoundary>
                </Router>
              </LocalizationProvider>

              {config.environment === Environment.Dev &&
                process.env.NODE_ENV === "development" && (
                  <ReactQueryDevtools position="bottom-right" />
                )}
            </MsalProvider>
          </StoreContextProvider>
        </ReactQueryProvider>
      </ThemeProvider>
    </AppInsightsContext.Provider>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
