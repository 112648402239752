import { createAddressValidationSchema } from "core/domain/Address";
import { DateTime } from "luxon";
import * as yup from "yup";

import {
  DateOfBirthSchema,
  MobileNumberValidationSchema,
  Person,
  PersonValidationSchema
} from "./Person";

export interface Dependant extends Omit<Person, "dateOfBirth"> {
  useAccountInfo: boolean;
  dateOfBirth: DateTime;
}

export const DependantValidationSchema = PersonValidationSchema.shape({
  useAccountInfo: yup.boolean(),
  mobile: yup.string().when("useAccountInfo", {
    is: false,
    then: MobileNumberValidationSchema
  }),

  dateOfBirth: DateOfBirthSchema.required(
    "Please enter a valid date of birth"
  ).max(
    () => DateTime.now().startOf("day"),
    "The date of birth cannot be after today."
  ),

  address: yup.object().when("useAccountInfo", {
    is: false,
    then: createAddressValidationSchema(true)
  })
});
