export enum BookingStep {
  Unknown = 0,
  SelectPatientType = 1,
  SelectAppointmentType = 2,
  AppointmentTypeInformation = 3,
  SelectAppointmentSlot = 4,
  CompleteProfile = 5,
  SelectPatient = 6,
  AddDependant = 7,
  AddPatientNotes = 8,
  ConfirmBooking = 9,
  BookingSuccess = 10,
  NotAcceptingBookings = 11,
  NotAcceptingNewPatients = 12,
  PracticeOutage = 13
  // to be defined for new step
}
